import React from 'react'
import RegisterComponent from "./register";

const RegisterAbandoned = (props) => {
    if (props && props.orderId && props.orderId.length > 0) {
        return <RegisterComponent orderId={props.orderId} />
    }
    return <RegisterComponent />
}

export default RegisterAbandoned